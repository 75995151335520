import {AppInsights} from 'applicationinsights-js';
import {addTelemetryInitializer} from './addTelemetryInitializer';
import {getJsonScriptContent} from '../utils/dom/getJsonScriptContent';

const element = document.getElementById('application-insights-options');

if (element) {
    const {
        config,
        context,
        tags,
        authenticatedUserContext,
        enableCorsCorrelation = false,
        pageViewTitle = null,
    } = getJsonScriptContent(element);

    config.enableCorsCorrelation = enableCorsCorrelation;

    AppInsights.downloadAndSetup(config);

    const {accountId, authenticatedUserId} = authenticatedUserContext;

    AppInsights.setAuthenticatedUserContext(accountId, authenticatedUserId);

    AppInsights.queue.push(() => {
        AppInsights.context.application.ver = context.applicationVersion;
        AppInsights.context.operation.id = context.operationId;
        AppInsights.context.operation.parentId = context.operationId;
        AppInsights.context.operation.name = context.operationName;
    });

    addTelemetryInitializer(envelope => {
        envelope.tags['ai.cloud.role'] = tags.cloudRole;
        envelope.tags['ai.cloud.roleInstance'] = tags.cloudRoleInstance;
    });

    if (pageViewTitle) {
        AppInsights.trackPageView(pageViewTitle);
    }
}
