export const getJsonScriptContent = (scriptElement, defaultContent = undefined) => {
    if (!scriptElement) {
        if (defaultContent) {
            return defaultContent;
        }

        throw new Error(`You must pass an element to getJsonScriptContent(), but you passed a falsy value! Did you use the wrong selector for your element?`);
    }

    if (typeof scriptElement === 'string') {
        throw new Error(`You must pass an element to getJsonScriptContent(), but you passed the string '${scriptElement}'!`);
    }

    const id = scriptElement.id ? `#${scriptElement.id}` : '<unidentified>';

    if (scriptElement.tagName !== 'SCRIPT') {
        throw new Error(`Element ${id} should be a 'script' element, but is a '${scriptElement.tagName.toLowerCase()}' element!`);
    }

    if (scriptElement.type !== 'application/json') {
        throw new Error(`Element ${id} doesn't have 'application/json' content type!`);
    }

    return JSON.parse(scriptElement.textContent);
};
